import Sortable from "stimulus-sortable"

export default class extends Sortable {

  static values = {
    persist: Boolean,
    model: String
  }


  connect(){
  //  alert("connected")
     super.connect()
     console.log(this.options)
  }


  end ({ item, newIndex }) {
        super.end({ item, newIndex })
        if(this.persistValue )
        {
          let data = new FormData()
          console.log(this.modelValue)
          data.append(this.modelValue + "[position]", newIndex + 1)
          fetch(item.dataset["nestedSortableUpdateUrl"],{
            method: "PATCH",
            body: data,
          }).then(function(response){
             if(response['status'] == 200)
             {
               item.classList.add("highlighting")
               let tr = item
               setTimeout(function(){ tr.classList.remove("highlighting")}, 3000)
             }
             else
             {
               alert("sorry unable to update positions  - please refresh the page and try again")
             }
          })
        }
        else
        {
          var elems = item.parentElement.getElementsByClassName("position_hidden_field")
          for (var i = 0; i < elems.length; i++) {
              elems[i].value = i + 1
          }
        }

    }
}
