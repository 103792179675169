import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {

    connect() {
        super.connect()
        console.log("Hello from nested form with position")
    }

    add(e) {
      console.log("adding")
        super.add(e)
        var elems = e.target.parentElement.parentElement.
                      getElementsByClassName("position_hidden_field")
        for (var i = 0; i < elems.length; i++) {
            elems[i].value = i + 1
        }
    }


    //custom method to add and remove based on a select choice
    selectAdd(e){
      if(e.target.value == "")
      {
        return
      }
      this.add(e)
      let container =  this.targetTarget.previousElementSibling
      let inp = container.getElementsByClassName("qual-id")[0]
      inp.value = e.target.value
      inp.nextElementSibling.innerHTML = "<b>"+e.target.options[e.target.selectedIndex].text+"</b>"
      document.getElementById("qual-errors").innerHTML=""
    }

}
