import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "videoPane", "searchForm", "studyId", "topicId", "searchButton"]
    static values = {
        parentType: String,
        parentId: Number
    }

    connect() {

    }

    fullSearch(e) {

        e.preventDefault()
        this.get_topic_phrases(e)
        this.get_study_dictionary(e)
        this.get_study_sessions(e)

    }

    get_topic_phrases(e) {
        let topic_id = this.topicIdTarget.value
        let study_id = this.studyIdTarget.value
        Rails.ajax({
            type: "GET",
            url: "/syllabus_admin/video_finders/get_topic_phrases",
            data: new URLSearchParams({
                topic_id: topic_id,
                study_id: study_id
            }),
            success: (data) => {
            },
        });
    }

    get_study_sessions(e) {
        let study_id = this.studyIdTarget.value
        Rails.ajax({
            type: "GET",
            url: "/syllabus_admin/video_finders/get_study_sessions",
            data: new URLSearchParams({
                study_id: study_id
            }),
            success: (data) => {
            },
        });
    }

    get_study_dictionary(e) {
        let topic_id = this.topicIdTarget.value
        let study_id = this.studyIdTarget.value

        Rails.ajax({
            type: "GET",
            url: "/syllabus_admin/video_finders/get_study_dictionary",
            data: new URLSearchParams({
                study_id: study_id
            }),
            success: (data) => {
            },
        });
    }

    get_modal(e) {
        e.preventDefault()
        let params = e.params
        Rails.ajax({
            type: "GET",
            url: "/syllabus_admin/video_finders/video_preview",
            data: new URLSearchParams({
                video_id: params.videoId,
                id: params.id,
                study_id: params.studyId,
                title: params.title,
                parent_type: this.parentTypeValue,
                parent_id: this.parentIdValue
            }),
            success: (data) => {
            },
        })
    }

}