import { Controller } from "stimulus"

export default class extends Controller {

    static values = { url: String}
    static targets = [ "study", "topic", "session"]

    connect(e) {
        this.load_child_list()
    }

    load_child_list(e) {
        console.log("load_child_lists")
        this.load_topics()
        this.load_sessions()
    }

    load_topics(e) {
        console.log("load_topics")
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        let study_id = this.studyTarget.options[this.studyTarget.selectedIndex].value

        Rails.ajax({
            type: "GET",
            url: '/syllabus_admin/video_finders/get_topics',
            data: "study_id=" + study_id,
            success: (data) => {
                this.updateDropdown(data, this.topicTarget);
            },
        });
    }

    load_sessions(e) {
        console.log("load_sessions")
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        let study_id = this.studyTarget.options[this.studyTarget.selectedIndex].value

        Rails.ajax({
            type: "GET",
            url: '/syllabus_admin/video_finders/get_sessions',
            data: "study_id=" + study_id,
            success: (data) => {
                this.updateDropdown(data, this.sessionTarget);
            },
        });
    }

    updateDropdown(data, list_to_update) {
        console.log(data)
        console.log(list_to_update)
        list_to_update.innerHTML = "";
        const recs = data;
        if (recs == 0) {
            console.log("recs = 0")
            const option = document.createElement("option");
            option.innerHTML = "";
            list_to_update.appendChild(option);
        } else {
            const option = document.createElement("option");
            option.value = "";
            option.innerHTML = "";
            list_to_update.appendChild(option);

            data.forEach((rec) => {
                console.log("processing" + rec.name )
                const option = document.createElement("option");
                option.value = rec.id;
                option.innerHTML = rec.name;
                list_to_update.appendChild(option);
            });
        }
    }




}
