import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["checkboxes"]
  static values = { max: Number }


  check_all(e) {
    e.preventDefault();
    let checkboxes = this.checkboxesTargets
    let checked    = checkboxes.filter(x => x.checked)

    if (checked.length == checkboxes.length) {
      checkboxes.forEach(ch => { ch.checked = false })
    }
    else {
      checkboxes.forEach(ch => { ch.checked = true })
    }
  }

  disable_at_max() {
    var selected = this.checkboxesTargets.filter(x => x.checked)
    var unselected = this.checkboxesTargets.filter(x => !x.checked)
    if (selected.length == parseInt(this.maxValue)) {
      unselected.forEach(x => x.disabled = true)
    }
    else {
      unselected.forEach(x => x.disabled = false)
    }
  }

}
