import { Controller } from "stimulus"

export default class extends Controller {

    static values = { url: String}
    static targets = [ "parent", "child"]

    connect(e) {
        this.load_child_list()
    }

    load_child_list(e) {
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        let parent_id = this.parentTarget.options[this.parentTarget.selectedIndex].value

        Rails.ajax({
            type: "GET",
            url: this.urlValue,
            data: "parent_id=" + parent_id,
            success: (data) => {
                this.updateDropdown(data);
            },
        });
    }

    updateDropdown(data) {
        this.childTarget.innerHTML = "";
        const topics = data;
        if (topics == 0) {
            const option = document.createElement("option");
            option.innerHTML = "";
            this.childTarget.appendChild(option);
        } else {
            data.forEach((topic) => {
                const option = document.createElement("option");
                option.value = topic.id;
                option.innerHTML = topic.name;
                this.childTarget.appendChild(option);
            });
        }
    }




}
