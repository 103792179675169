import { Controller } from "stimulus"

const axios = require('axios')

export default class extends Controller {

  static targets = ["questions","jobs"]

  connect(){
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';
    this.questionsCount()
    this.jobsCount()
  }

  questionsCount(){
    let that=this
    axios.get('/teacher/questions',).then(response => {
      that.questionsTarget.innerHTML = response.data.count
    })
  }

  jobsCount(){
    let that=this
    axios.get('/teacher/jobs',).then(response => {
      that.jobsTarget.innerHTML = response.data.count
    })
  }
}
