/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

import { Application } from "@hotwired/stimulus"
import NestedForm from "stimulus-rails-nested-form"
import Sortable from "stimulus-sortable"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
//Default implementations of stimulus-components https://www.stimulus-components.com/docs/stimulus-sortable/
application.register("nested-form", NestedForm)
application.register("sortable", Sortable)
//application.debug = process.env.NODE_ENV === 'development'

require("trix")
require("@rails/actiontext")

import "controllers"
import "axios"
